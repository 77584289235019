import React, { useEffect, useRef, useState } from "react";
import PromptConfig from "../playground/prompt-config";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { saveAs } from "file-saver";
import { Input } from "@/components/ui/input";
import { Pencil, Trash2 } from "lucide-react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import DeleteConfirfationDialog from "./delete-confirfation-dialog";
import { useNavigate, useParams } from "react-router-dom";
import useDataset from "@/actions/api/usePromptDataset";
import AddDatasetButton from "./add-dataset-button";
import { toast } from "sonner";
import useUpdatePromptDataset from "@/actions/api/useUpdatePromptDataset";
import moment from "moment";

const CURRENT_DATASET_DATA = "CURRENT_DATASET_DATA";
type LocalDatasetDataType = {
  timestamp: number;
  prompts: Prompt[];
  _id?: string;
};

export type Prompt = {
  name: string;
  messages: {
    role: string;
    content: string;
  }[];
};

export type Dataset = {
  _id?: string;
  name: string;
  prompts: Prompt[];
  version?: number;
  timestamp?: number;
};

const ParticularDataset = () => {
  const promptContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { dataset_id: datasetId } = useParams();
  const updatePromptDataset = useUpdatePromptDataset(datasetId || "");
  const updatePromptDatasetForceProd = useUpdatePromptDataset(
    datasetId || "",
    "prod"
  );
  const updatePromptDatasetForceQa = useUpdatePromptDataset(
    datasetId || "",
    "qa"
  );

  const { data: dataset, isLoading: isDatasetLoading } = useDataset(
    datasetId || ""
  );

  const [promptEditInd, setPromptEditInd] = useState(-1);
  const [activeInd, setActiveInd] = useState(-1);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [error, setError] = useState("");
  const [stateMismatchData, setStateMisMatchData] = useState({
    dbTimestamp: 0,
    localTimestamp: 0,
    isPopupOpen: false,
    localDataset: null as null | LocalDatasetDataType,
    dbDataset: null as null | Dataset,
  });

  function addItemToIndexedDB(item: any) {
    const request = indexedDB.open("MyDatabase", 1);

    request.onsuccess = function (event: any) {
      const db = event.target.result;
      const transaction = db.transaction(["MyStore"], "readwrite");
      const store = transaction.objectStore("MyStore");

      const addRequest = store.add(item); // `item` must have an `id` property if keyPath is 'id'

      addRequest.onsuccess = function () {
        console.log("Item added successfully");
      };

      addRequest.onerror = function (event: any) {
        console.error("Error adding item:", event.target.error);
      };
    };
  }

  function removeItemFromIndexedDB(key: string) {
    const request = indexedDB.open("MyDatabase", 1);

    request.onsuccess = function (event: any) {
      const db = event.target.result;
      const transaction = db.transaction(["MyStore"], "readwrite");
      const store = transaction.objectStore("MyStore");

      const deleteRequest = store.delete(key);

      deleteRequest.onsuccess = function () {
        console.log("Item removed successfully");
      };

      deleteRequest.onerror = function (event: any) {
        console.error("Error removing item:", event.target.error);
      };
    };
  }

  const handleAddPrompt = () => {
    setPrompts((curr) => [
      ...curr,
      {
        name: "NEW_PROMPT",
        messages: [],
      },
    ]);

    setTimeout(() => {
      if (promptContainerRef?.current) {
        promptContainerRef.current.lastElementChild?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 0);
  };

  const checkForErrors = (messages: Prompt["messages"]): boolean => {
    // let prevRole: string = "";
    console.log({ messages });
    for (let ind in messages) {
      const message = messages[ind];

      if (!message.role) {
        setError(`Role is not defined at index ${ind}!!`);
        return false;
      }

      // Consecutive user/assistant role items found
      // if (message.role === prevRole) {
      //   setError(`Consecutive ${prevRole} role found at index ${ind}!!`);
      //   return false;
      // }
      // prevRole = message.role as string;
    }

    return true;
  };

  const handleSaveDatasetAsLatest = async ({
    commitMessage,
    chats,
    forcePushTo,
  }: {
    commitMessage: string;
    chats: Prompt["messages"];
    forcePushTo?: "prod" | "qa";
  }) => {
    let error = false;
    const finalPrompts = prompts.map((prompt, i) => {
      if (i === activeInd) {
        if (!checkForErrors(chats)) {
          error = true;
        }
        return { ...prompt, messages: chats };
      }
      if (!checkForErrors(prompt["messages"])) {
        error = true;
      }
      return prompt;
    });

    if (error) return;

    let actionFn = updatePromptDataset;
    if (forcePushTo === "prod") {
      actionFn = updatePromptDatasetForceProd;
    } else if (forcePushTo === "qa") {
      actionFn = updatePromptDatasetForceQa;
    }

    await actionFn
      .mutateAsync({
        dataset: {
          ...dataset,
          prompts: finalPrompts,
        },
        commit_message: commitMessage,
      })
      .then((res) => {
        toast.success("Prompt dataset added successfully!");
        navigate(`/dataset/${res.dataset_id}`);
      })
      .catch(() => {
        toast.error("Oops! Something went wrong. Please try again");
      });
  };

  const onDownloadDataset = () => {
    var fileToSave = new Blob([JSON.stringify(prompts)], {
      type: "application/json",
    });

    // Save the file
    saveAs(fileToSave);
  };

  const handleChangeMessages = (messages: Prompt["messages"], ind: number) => {
    setPrompts((curr) => {
      const dummy = curr.map((prompt, i) =>
        i === ind ? { ...prompt, messages } : prompt
      );
      return dummy;
    });
  };

  const handleSaveToLocal = (messages: Prompt["messages"], index: number) => {
    const dummyPrompts = prompts.map((prompt, ind) =>
      ind === index ? { ...prompt, messages } : prompt
    );

    // To store updates in localstorage
    if (dummyPrompts.length)
      // localStorage.setItem(
      //   CURRENT_DATASET_DATA,
      //   JSON.stringify({
      //     timestamp: Date.now(),
      //     prompts: dummyPrompts,
      //     _id: dataset?._id,
      //   })
      // );

      addItemToIndexedDB({
        timestamp: Date.now(),
        prompts: dummyPrompts,
        _id: dataset?._id,
      });
  };

  const handleChooseDataset = (state: "local" | "db") => {
    setPrompts(
      state === "local"
        ? stateMismatchData.localDataset?.prompts || []
        : stateMismatchData.dbDataset?.prompts || []
    );
    setActiveInd(0);
    setStateMisMatchData({
      ...stateMismatchData,
      isPopupOpen: false,
    });
  };

  useEffect(() => {
    const resetPromptEdit = () => {
      setPromptEditInd(-1);
    };
    window.addEventListener("click", resetPromptEdit);

    return () => {
      window.removeEventListener("click", resetPromptEdit);
    };
  }, [datasetId]);

  useEffect(() => {
    if (!!error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  useEffect(() => {
    if (dataset?.prompts) {
      // We have access to both database dataset and localstorage dataset

      // const localDataset: LocalDatasetDataType = JSON.parse(
      //   localStorage.getItem(CURRENT_DATASET_DATA) || "{}"
      // );

      const request = indexedDB.open("MyDatabase", 1);

      request.onsuccess = function (event: any) {
        const db = event.target.result;
        const transaction = db.transaction(["MyStore"], "readonly");
        const store = transaction.objectStore("MyStore");

        const getRequest = store.get(dataset?._id);

        getRequest.onsuccess = function () {
          if (getRequest.result) {
            // console.log('Item retrieved:', getRequest.result);
            const localDataset = getRequest.result;

            if (datasetId === localDataset?._id) {
              if (localDataset.timestamp !== dataset?.timestamp)
                setStateMisMatchData({
                  dbTimestamp: dataset?.timestamp,
                  localTimestamp: localDataset.timestamp,
                  isPopupOpen: true,
                  localDataset,
                  dbDataset: dataset,
                });
            } else {
              setPrompts(dataset?.prompts);
              setActiveInd(0);
            }
          } else {
            console.log("No item found with the key");
            setStateMisMatchData({
              dbTimestamp: dataset?.timestamp,
              localTimestamp: 0,
              isPopupOpen: true,
              localDataset: null,
              dbDataset: dataset,
            });
          }
        };

        getRequest.onerror = function (event: any) {
          console.error("Error reading item:", event.target.error);
        };
      };

      request.onerror = function (event: any) {
        console.error("Error opening database:", event.target.errorCode);
      };
    }

    if (dataset?.detail) {
      toast.error("Couldn't find the prompt!!");
      navigate("/dataset");
    }
  }, [dataset, datasetId, navigate]);

  useEffect(() => {
    const request = indexedDB.open("MyDatabase", 1);
    request.onupgradeneeded = function (event: any) {
      const db = event.target.result;

      // Create an object store if it doesn't already exist
      if (!db.objectStoreNames.contains("MyStore")) {
        db.createObjectStore("MyStore", { keyPath: "_id" }); // Use 'id' as the key
      }
    };
    request.onsuccess = function (event) {
      console.log("Database opened successfully");
    };

    request.onerror = function (event: any) {
      console.error("Error opening database:", event.target.errorCode);
    };
  }, []);

  return (
    <div className="px-5 py-2 grow flex flex-col">
      <div className="grow flex h-0 overflow-auto">
        <div className="basis-1/5 border-r px-2 flex flex-col overflow-auto">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">
              Dataset: {dataset?.name || ""}
            </h3>
            <AddDatasetButton />
          </div>

          <Tabs
            defaultValue="prompts"
            className="w-full mt-2 grow flex flex-col  overflow-auto"
          >
            <TabsList className="w-fit">
              <TabsTrigger value="prompts">Prompts</TabsTrigger>
              <TabsTrigger value="versions">Versions</TabsTrigger>
            </TabsList>
            <TabsContent value="prompts" asChild>
              <>
                <div
                  className="grow flex flex-col gap-2 mt-5 mb-2 overflow-auto p-2"
                  ref={promptContainerRef}
                >
                  {prompts.map((prompt, ind) => (
                    <div key={ind}>
                      {promptEditInd === ind ? (
                        <Input
                          value={prompt.name}
                          onChange={(e) =>
                            setPrompts((curr) =>
                              curr.map((prompt, i) =>
                                ind === i
                                  ? { ...prompt, name: e.target.value }
                                  : prompt
                              )
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                          autoFocus
                        />
                      ) : (
                        <div
                          className={cn(
                            "cursor-pointer p-2 text-sm rounded hover:bg-[#f2f2ff] flex justify-between",
                            activeInd === ind && "bg-[#f2f2ff]"
                          )}
                          onClick={() => setActiveInd(ind)}
                        >
                          {prompt.name}
                          <div className="flex gap-2">
                            <Pencil
                              className="w-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                setPromptEditInd(ind);
                              }}
                            />
                            <Dialog>
                              <DialogTrigger
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Trash2 className="w-3  text-red-300" />
                              </DialogTrigger>
                              <DialogContent>
                                <DeleteConfirfationDialog
                                  onDelete={() =>
                                    setPrompts((curr) =>
                                      curr.filter((_, i) => ind !== i)
                                    )
                                  }
                                />
                              </DialogContent>
                            </Dialog>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex gap-2">
                  <Button onClick={handleAddPrompt} className="basis-1/2">
                    App Prompt
                  </Button>
                  <Button onClick={onDownloadDataset} className="basis-1/2">
                    Download Dataset
                  </Button>
                </div>
              </>
            </TabsContent>

            <TabsContent value="versions">
              This will contain the dataset versions
            </TabsContent>
          </Tabs>
        </div>

        <div className="basis-4/5 px-2">
          {!isDatasetLoading && (
            <PromptConfig
              key={`prompts-${activeInd}`}
              prompt={prompts?.[activeInd]?.messages || []}
              showRunBtn={false}
              handleSavePromptAsLatest={handleSaveDatasetAsLatest}
              onChangeChats={handleChangeMessages}
              handleSaveToLocal={handleSaveToLocal}
              activeInd={activeInd}
              isDataset={true}
            />
          )}
        </div>
      </div>
      <Dialog open={stateMismatchData.isPopupOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              There's a mismatch between db state and local state
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-3">
            <p>
              Timestamp for local state is:{" "}
              <span className="font-semibold">
                {moment(stateMismatchData.localTimestamp).format(
                  "DD-MM-YYYY hh:mm:ss"
                )}
              </span>
            </p>
            <p>
              Timestamp for db state is:{" "}
              <span className="font-semibold">
                {moment(stateMismatchData.dbTimestamp).format(
                  "DD-MM-YYYY hh:mm:ss"
                )}
              </span>
            </p>
          </div>
          <DialogFooter>
            <Button onClick={() => handleChooseDataset("local")}>
              Choose local state
            </Button>
            <Button onClick={() => handleChooseDataset("db")}>
              {" "}
              Choose Database state
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ParticularDataset;
